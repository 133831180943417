





















import { activeCallStore } from '@/store'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { IconUsersM } from '@tada/icons'
import { TdButtonCall } from 'td-ui'

@Component({
  components: {
    IconUsersM,
    TdButtonCall,
  },
})
export default class CallDetailsHeader extends Vue {
  @Prop({
    type: Number,
    required: true,
  }) readonly numConnectedMembers!: number

  private inviteMembers (): void {
    const displayType = activeCallStore.getters.uiDisplayTypeCode
    if (displayType === 'SM') activeCallStore.actions.setUiDisplayType('bar')

    const message = `Кнопка-иконка «Добавить участников» в ПВУ ${displayType}`
    window.goal('callControls', { callControls: message })
    activeCallStore.actions.toggleAddMembersDisplay()
  }
}
